/**
 * trace
 * @author wwl
 * @created 2023/10/17
 * @modified 2023/10/17
 */
export enum TraceCode {
  SUCCESS = 1,
  FAIL = 2,
  // 单据付费类型
  PAY = 1, // 付费
  FREE = 2, // 免费
  // 模块
  MO_COMMON = 'MO_COMMON',
  MO_HOME = 'MO_HOME', // 首页
  MO_NEWS = 'MO_NEWS', // 资讯
  MO_PAVILION = 'MO_PAVILION', // 会展中心
  MO_FAIR = 'MO_FAIR', // 展会
  MO_FAIR_BOOTH = 'MO_FAIR_BOOTH', // 展位
  MO_FAIR_PERIODICAL = 'MO_FAIR_PERIODICAL', // 期刊
  MO_FAIR_TICKET = 'MO_FAIR_TICKET', // 门票
  MO_HOTEL = 'MO_HOTEL', // 酒店
  MO_VISA = 'MO_VISA', // 签证
  MO_AIR_TICKET = 'MO_AIR_TICKET', // 机票
  // 业务 Code
  CC_FAIR_LIST = 'CC_FAIR_LIST', // 展会列表
  CC_FAIR_DETAIL = 'CC_FAIR_DETAIL', // 展会详情

  CC_FAIR_TICKET = 'CC_FAIR_TICKET', // 会展门票下单
  CC_TICKET_IN = 'CC_TICKET_IN', // 跳转站内门票预订
  CC_TICKET_OUT = 'CC_TICKET_OUT', // 跳转站外门票预订
  CC_SUBMIT_TICKET = 'CC_SUBMIT_TICKET', // 门票-提交订单
  CC_TO_ALIPAY_TICKET = 'CC_TO_ALIPAY_TICKET', // 门票-去支付(支付宝)
  CC_TO_WXPAY_TICKET = 'CC_TO_WXPAY_TICKET', // 门票-去支付(微信)
  CC_FAIR_PAY_SUCCESS = 'CC_FAIR_PAY_SUCCESS', // 支付成功

  CC_SUBMIT_BOOTH = 'CC_SUBMIT_BOOTH', // 展位-立即提交
  CC_APPLY_BOOTH = 'CC_APPLY_BOOTH', // 展位-展位咨询

  CC_APPLY_PERIODICAL = 'CC_APPLY_PERIODICAL', // 展商名录获取
  CC_SUBMIT_PERIODICAL = 'CC_SUBMIT_PERIODICAL', // 期刊-下一步，去支付
  CC_TO_ALIPAY_PERIODICAL = 'CC_TO_ALIPAY_PERIODICAL', // 期刊-去支付(支付宝)
  CC_TO_WXPAY_PERIODICAL = 'CC_TO_WXPAY_PERIODICAL', // 期刊-去支付(微信)
  CC_PERIODICAL_PAY_SUCCESS = 'CC_PERIODICAL_PAY_SUCCESS', // 支付成功

  CC_NEWS_LIST = 'CC_NEWS_LIST', // 资讯列表
  CC_NEWS_DETAIL = 'CC_NEWS_DETAIL', // 资讯详情

  CC_PAVILION_LIST = 'CC_PAVILION_LIST', // 会展中心列表
  CC_PAVILION_DETAIL = 'CC_PAVILION_DETAIL', // 会展中心详情
}
